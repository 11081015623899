import { Pipe, PipeTransform } from '@angular/core';
import { OrderProduct } from '@app/core/entities/order.interface';
import { Product } from '@app/core/entities/product.interface';
import { environment } from '@env/environment';

export function getProductImage(product: Product | OrderProduct, bigSize = false): string {
  return product.image
    ? product.image.startsWith('http')
      ? product.image
      : `${environment.storageUrl}${product.image}_${bigSize ? 'medium' : 'small'}.jpg`
    : '';
}

@Pipe({
  standalone: true,
  name: 'productImage',
})
export class ProductImagePipe implements PipeTransform {
  transform(value: Product | OrderProduct, bigSize = false): string {
    return getProductImage(value, bigSize);
  }
}
